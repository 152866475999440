import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ed05993"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  title: "View List",
  "aria-label": "view list of online players",
  target: "_blank",
  rel: "noopener",
  href: "https://server.themanaworld.org"
}
const _hoisted_2 = {
  key: 1,
  title: "View List",
  "aria-label": "view list of online players",
  target: "_blank",
  rel: "noopener",
  href: "https://server.themanaworld.org"
}
const _hoisted_3 = {
  key: 2,
  class: "offline",
  title: "???",
  "aria-label": "open a YouTube video",
  target: "_blank",
  rel: "noopener",
  href: "https://www.youtube-nocookie.com/embed/ILVfzx5Pe-A?autoplay=1&modestbranding=1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("aside", null, [
    (_ctx.Online && _ctx.Players)
      ? (_openBlock(), _createElementBlock("a", _hoisted_1, "Online: " + _toDisplayString(_ctx.Players) + " players", 1))
      : _createCommentVNode("", true),
    (_ctx.Online && !_ctx.Players)
      ? (_openBlock(), _createElementBlock("a", _hoisted_2, "Online"))
      : _createCommentVNode("", true),
    (!_ctx.Online)
      ? (_openBlock(), _createElementBlock("a", _hoisted_3, "Offline"))
      : _createCommentVNode("", true)
  ]))
}