<template>
	<footer class="footer">
		&copy; 2004&ndash;{{ (new Date()).getFullYear() }} The Mana World
	</footer>
</template>

<style scoped>
.footer {
	text-align: right;
	font-size: 8pt;
	padding: 5px;
}
</style>
